/* Fonts */




/* CSS */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
  scroll-padding-top: 50px;
}

body {
  font-weight: 400;
  overflow-x: hidden;
  position: relative;
  background-color: 	#ceb195 !important;
  color:rgba(255, 255, 255, 0.1) !important;
  font-family:'Times New Roman', Times, serif !important;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: normal;
}

p, a, li, button, ul {
  margin: 0;
  padding: 0;
  line-height: normal;
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

img {
  width: 70%;
  height: auto;
}

button {
  border: 0;
  background-color: transparent;
}

input:focus, textarea:focus, select:focus {
  outline: none;
}

@media (min-width:1700px) {
	main .container {
		max-width: 100%;
		padding: 0 150px;
	}
}

p.success {
	color: green;
}

p.danger {
	color: red;
}
/************ Navbar Css ************/
nav.navbar {
  padding: 18px 0;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 9999;
  transition:  0.32s ease-in-out;
}
nav.navbar.scrolled {
  padding: 0px 0;
  background-color: #e1cab5;
}
nav.navbar a.navbar-brand {
    width: 9%;
}
nav.navbar .navbar-nav .nav-link.navbar-link {
  font-weight: 400;
  color: #121212 !important;
  letter-spacing: 0.8px;
  padding: 0 25px;
  font-size: 18px;
  opacity: 0.75;
}
nav.navbar .navbar-nav a.nav-link.navbar-link:hover,
nav.navbar .navbar-nav a.nav-link.navbar-link.active {
    opacity: 1;
}
span.navbar-text {
    display: flex;
    align-items: center;
}

h3.navbar-text {
  display: flex;
  align-items: center;
}

.navbar-text button {
  font-weight: 700;
  color: black;
  border: 1px solid black;
  padding: 18px 34px;
  font-size: 18px;
  margin-left: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}
.navbar-text button span {
  z-index: 1;
}
.navbar-text button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: rgb(46, 45, 45);
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.navbar-text button:hover {
  color: #ceb195;
}
.navbar-text button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
nav.navbar .navbar-toggler:active,
nav.navbar .navbar-toggler:focus {
	outline: none;
    box-shadow: none;
}
nav.navbar .navbar-toggler-icon {
  width: 24px;
  height: 17px;
  background-image: none;
  position: relative;
  border-bottom: 2px solid #fff;
  transition: all 300ms linear;
  top: -2px;
}
nav.navbar .navbar-toggler-icon:focus {
	border-bottom: 2px solid #fff;
}
nav.navbar .navbar-toggler-icon:after,
nav.navbar .navbar-toggler-icon:before {
	width: 24px;
	position: absolute;
	height: 2px;
	background-color: #fff;
	top: 0;
	left: 0;
	content: '';
	z-index: 2;
	transition: all 300ms linear;
}
nav.navbar .navbar-toggler-icon:after {
	top: 8px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:after {
	transform: rotate(45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon:before {
	transform: translateY(8px) rotate(-45deg);
	background-color: #fff;
	height: 2px;
}
nav.navbar .navbar-toggler[aria-expanded="true"] .navbar-toggler-icon {
	border-color: transparent;
}


/************ Banner Css ************/
.banner {
  margin-top: 0;
  padding: 150px 0 100px 0;
  background-position: top center;
  background-size: cover;
  background-repeat: no-repeat;
}
.banner .tagline {
  color:rgb(49, 47, 47);
  font-weight: 700;
  letter-spacing: 0.8px;
  padding: 8px 10px;
  border: 1px solid black;
  font-size: 20px;
  margin-bottom: 16px;
  display: inline-block;
}
.banner h1 {
  color: rgb(49, 47, 47);
  font-size: 50px;
  font-family: 'Times New Roman', Times, serif;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.8px;
  line-height: 1;
  margin-bottom: 20px;
  display: block;
}
.banner p {
  color: rgb(54, 53, 53);
  font-size: 20px;
  letter-spacing: 0.8px;
  line-height: 1.5em;
  width: 96%;
}

.banner button {
  font-weight: 700;
  color: black;
  border: 1px solid black;
  padding: 18px 34px;
  font-size: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}

.banner button span {
  z-index: 1;
}
.banner button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: rgb(46, 45, 45);
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.banner button:hover {
  color: #ceb195;
}
.banner button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}
.banner img {
  animation: updown 3s linear infinite;
}
@keyframes updown {
    0% {
        transform: translateY(-20px);
    }
    50% {
        transform: translateY(20px);
    }
    100% {
        transform: translateY(-20px);
    }
}
.txt-rotate > .wrap {
  border-right: 0.08em solid #666;
}

/************ Skills Css ************/
.skill {
  padding: 0 0 50px 0;
  position: relative;
}
.skill-bx {
  border-radius: 64px;
  text-align: center;
  padding: 60px 50px;
  margin-top: -60px;
}
.skill h2 {
  color: rgb(49, 47, 47);
	font-size: 45px;
	font-weight: 700;
}
.skill p {
    color: rgb(54, 53, 53);
    font-size: 20px;
    letter-spacing: 0.8px;
    line-height: 1.5em;
    margin: 14px 0 75px 0;
}
.skill-slider {
  width: 80%;
  margin: 0 auto;
  position: relative;
}
.skill-slider .item img {
  width: 50%;
  margin: 0 auto 15px auto;
}
.background-image-left {
  top: 1%;
  position: relative;
  bottom: 0;
  width: 20%;
  z-index: -4;
}

.skill button {
  font-weight: 700;
  color: black;
  border: 1px solid black;
  padding: 18px 34px;
  font-size: 18px;
	position: relative;
	background-color: transparent;
	transition: 0.3s ease-in-out;
}

.skill button span {
  z-index: 1;
}
.skill button::before {
  content: "";
  width: 0%;
  height: 100%;
  position: absolute;
  background-color: rgb(46, 45, 45);
  left: 0;
  top: 0;
  z-index: -1;
  transition: 0.3s ease-in-out;
}
.skill button:hover {
  color: #ceb195;
}
.skill button:hover::before {
	content: "";
	width: 100%;
	height: 100%;
	position: absolute;
}

/************ Experience Css ************/
.experience-page {
  padding-top: 120px; /* Account for fixed navbar */
  min-height: 100vh;
  background-color: #ceb195;
}

.experience-section {
  padding: 40px 0;
}

.experience-container {
  display: flex;
  gap: 30px;
  padding: 20px;
}

.experience-half {
  flex: 1;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 20px;
  padding: 30px;
  backdrop-filter: blur(10px);
}

.experience-half h2 {
  color: rgb(49, 47, 47);
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 30px;
  text-align: center;
}

.experience-item {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 25px;
  margin-bottom: 20px;
}

.experience-item h3 {
  color: rgb(49, 47, 47);
  font-size: 24px;
  margin-bottom: 10px;
}

.experience-item p {
  color: rgb(54, 53, 53);
  font-size: 18px;
  margin-bottom: 5px;
}

.experience-item p a{
  color: rgb(54, 53, 53);
  text-decoration: none;
}

.experience-item p a:hover{
  color: rgb(126, 93, 46);
  text-decoration: underline;
}

.experience-item .date {
  color: rgb(54, 53, 53);
  font-style: italic;
}

/* Responsive design for experiences */
@media (max-width: 768px) {
  .experience-container {
    flex-direction: column;
  }
  
  .experience-half {
    width: 100%;
  }
  
  .experience-half h2 {
    font-size: 32px;
  }
}


/************ Footer Css ************/
.footer {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color:  #b2977e;
}

.footer p {
  font-weight: 500;
  font-size: 20px;
  color: black;
  letter-spacing: 0.5px;
  margin-top: 20px;
  margin: 14px 0 75px 0;
  position: relative;
}

/* Projects Page Styles */
.projects-page {
  padding-top: 120px;
  min-height: 100vh;
  background-color: #ceb195;
}

.projects-title {
  color: rgb(49, 47, 47);
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 40px;
  text-align: left;
  padding-left: 20px;
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.project-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 15px;
  padding: 20px;
  transition: transform 0.3s ease;
  backdrop-filter: blur(10px);
}

.project-image {
  width: 100%;
  height: 200px;
  margin-bottom: 20px;
}

.project-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.project-info {
  text-align: left;
}

.project-title {
  color: rgb(49, 47, 47);
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 8px;
  border-bottom: 2px solid rgb(49, 47, 47);
  padding-bottom: 5px;
  display: inline-block;
}

.project-description {
  color: rgb(54, 53, 53);
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
}

.project-link {
  display: inline-block;
  color: #312f2f;
  text-decoration: underline;
  font-weight: 500;
  margin-top: 10px;
  transition: all 0.3s ease;
}

.project-link:hover {
  text-decoration: underline;
  color: rgb(126, 93, 46);
  transform: translateX(5px);
}

/* Responsive Design */
@media (max-width: 1200px) {
  .projects-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 992px) {
  .projects-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .projects-grid {
    grid-template-columns: 1fr;
  }
  
  .projects-title {
    font-size: 32px;
    margin-bottom: 30px;
  }
  
  .project-title {
    font-size: 20px;
  }
}

.connect-page {
  padding-top: 70px;
  min-height: 100vh;
  background-color: #ceb195;
  display: flex;
  align-items: center;
}

.connect-content {
  text-align: center;
  padding: 40px 0;
}

.connect-content h2 {
  color: rgb(49, 47, 47);
  font-size: 45px;
  font-weight: 700;
  margin-bottom: 20px;
}

.connect-content p {
  color: rgb(54, 53, 53);
  font-size: 20px;
  margin-bottom: 40px;
}

.connect-links {
  display: flex;
  justify-content: center;
  gap: 30px;
  flex-wrap: wrap;
}

.connect-button {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 15px 30px;
  border: 1px solid rgb(49, 47, 47);
  border-radius: 5px;
  background: transparent;
  color: rgb(49, 47, 47);
  font-size: 18px;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.connect-button::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 100%;
  background-color: rgb(46, 45, 45);
  transition: all 0.3s ease;
  z-index: 1;
}

.connect-button:hover::before {
  width: 100%;
}

.connect-button span {
  position: relative;
  z-index: 2;
  transition: color 0.3s ease;
}

.connect-button:hover span {
  color: #ceb195;
}

.connect-icon {
  width: 24px;
  height: 24px;
  object-fit: cover;
  position: relative;
  z-index: 2;
}

/* Responsive design */
@media (max-width: 768px) {
  .connect-content h2 {
    font-size: 32px;
  }
  
  .connect-content p {
    font-size: 16px;
  }
  
  .connect-button {
    padding: 12px 25px;
    font-size: 16px;
  }
  
  .connect-links {
    gap: 20px;
  }
}

.highlight {
  background: rgba(255, 233, 125, 0.4); /* Light yellow with some transparency */
  border-radius: 4px;
}